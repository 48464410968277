<template>
    <div v-if="capitalOneToken && vehicle">
        <button
            class="capital-one-stacked-button"
            :data-client-token="capitalOneToken"
            :data-sales-price="vehicle.price.selling"
            :data-vehicle-image-url="vehicle.image"
            :data-vin="vehicle.vin"
        >
            Explore Financing
        </button>
    </div>
    <div v-else class="block btn btn-secondary w-full text-center">
        CapitalOne ID Not Found
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { getSetting } from '~/utils/functions'
import type { Vehicle } from '~/utils/types/inventory'

const domainStore = useDomainStore()

defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const capitalOneToken = computed(() =>
    getSetting(domainStore.autoZnetworkSettings, 'vendor.capitalone.token'),
)

if (capitalOneToken.value) {
    useScript({
        src: 'https://auto-digital-retail.capitalone.com/loader.js',
        crossorigin: false,
    })
}
</script>

<style scoped>
.capital-one-stacked-button {
    display: none;
}
</style>
